import React, { Suspense, lazy
  // , useState, useEffect, Fragment
 } from "react";
import { BrowserRouter as Router, Route, Switch, Redirect } from "react-router-dom";
// import Loadable from "react-loadable";

import MainLayout from "./MainLayout";
import AdvisorMainLayout from "./AdvisorMainLayout";
import SubdomainNotVerifiedMainLayout from "./SubdomainNotVerifiedMainLayout";

import AppLayout from "./AppLayout";

// import NotFound from './NotFound';
import Page404 from "./Page404";
import PrivateRoute from "./PrivateRoute";
import ContentLoader from "../components/Loader/ContentLoader";
import UploadRtas from "../containers/Rta/UploadRtas";
//import AdvisorHome from "../containers/Home/AdvisorHome";
// import { verifySubdomain } from "../../src/actions/advisor";

//lazy loading component
// Home components
// const Home = lazy(() => import("../containers/Home/Home"));
const Home1 = lazy(() => import("../containers/Home/Home1"));
const SubdomainNotVerifiedHome = lazy(() => import("../containers/Home/SubdomainNotVerifiedHome"));

//pre login nav
const ToolsResources = lazy(() => import("../containers/Home/Tools&Resources"));

const Investment = lazy(() => import("../containers/Investment/InvestmentMain"));
const AdvisorSignUp = lazy(() => import("../containers/Home/Signup"));
const AssetMapping = lazy(() => import("../containers//AssetMapping/AssetMapping"));
const Login = lazy(() => import("../containers/Login/Login"));
const AdvisorLogin = lazy(() => import("../containers/Login/AdvisorLogin"));
const ForgotPassword = lazy(() => import("../containers/ForgotPassword/ForgotPassword"));
const ResetPassword = lazy(() => import("../containers/ForgotPassword/ResetPassword"));
// const Login = lazy(() => import("../containers/Login/LoginOld"));
// const AdvisorSignUpModal = lazy(() => import("../containers/PreLoginAdvisor/AdvisorSignUpModal"));
const AdvisorBlogs = lazy(() => import("../containers/PreLoginAdvisor/AdvisorBlog"));
const AdvisorBlogDetail = lazy(() => import("../containers/PreLoginAdvisor/AdvisorBlogDetail"));
const AboutUs = lazy(() => import("../containers/PreLoginAdvisor/AboutUs"));
const Privacy = lazy(() => import("../containers/PreLoginAdvisor/Privacy"));
const TermsCondition = lazy(() => import("../containers/PreLoginAdvisor/TermsCondition"));
const Refund = lazy(() => import("../containers/PreLoginAdvisor/Refund"));
const UserJourney = lazy(() => import("../containers/AdminSetting/UserJourney"));
const AdminDashboard = lazy(() => import("../containers/AdminDashboard/AdminDashboard"));
//const MyAdvisors = lazy(() => import("../containers/MyAdvisors/MyAdvisors"));
const ClientMappingMain = lazy(() => import("../containers//ClientMappingMain"));
const NewsLetter = lazy(() => import("../containers/NewsLetter"));
const AdvisorDashboard = lazy(() => import("../containers/AdvisorDashboard/AdvisorDashboard"));
const MyClients = lazy(() => import("../containers/MyClient/MyClients"));
const fundAnalyticsMain = lazy(() => import("../containers/FundAnalytics/fundAnalyticsMain"));
const PortfolioAnalyticsMain = lazy(() =>
  import("../containers/PortfolioAnalytics/PortfolioAnalyticsMain")
);
const AdminSettingMain = lazy(() => import("../containers/AdminSetting/AdminSettingMain"));

const Details = lazy(() => import("../containers/RiskProfiling/Details"));
const DreamsNav = lazy(() => import("../containers/ClientDashboard/DreamsNav"));
const Brief = lazy(() => import("../containers/RiskProfiling/Brief"));
const MandatoryStep = lazy(() => import("../containers/MandatoryStep/MandatoryStep"));
const OnBoardingStep = lazy(() => import("../containers/NewOnBoardingFlow/OnBoardingMain"));
const AdvisorMandatoryStep = lazy(() => import("../containers/MandatoryStep/AdvisorMandatoryStep")); 
const ClientDashboard = lazy(() => import("../containers/ClientDashboard/ClientDashboard"));
const AccountCreation = lazy(() => import("../containers/AccountCreation/AccountCreation"));
const RtaAdmin = lazy(() => import("../containers/Rta/Rta"));
const DreamsMain = lazy(() => import("../containers/ClientDashboard/DreamsMain"));
const PaymentPage = lazy(() => import("../containers/ClientDashboard/PaymentPage"));
const IdeasMain = lazy(() => import("../containers/ClientDashboard/IdeasMain"));
const AssetsMain = lazy(() => import("../containers/Assets/assetsMain"));
const BusinessAnalyticsMain = lazy(() =>
  import("../containers/BusinessAnalytics/businessAnalyticsMain")
);
const Transaction = lazy(() => import("../containers/Transaction/Transaction"));
const HoldingMain = lazy(() => import("../containers/Holding/holdingMain"));
const OnBoarding = lazy(() => import("../containers/OnBoarding/OnBoarding"));
// const Rta = Loadable({
//   loader: () => import('../containers/OnBoarding/Rta/Rta'),
//   loading: () => <div></div>,
// });
// const Crm = Loadable({
//   loader: () => import('../containers/OnBoarding/Crm/Crm'),
//   loading: () => <div></div>,
// });
const Cart = lazy(() => import("../containers/Cart/Cart"));
const HubspotForm = lazy(() => import("../containers/HubspotForm"));
const HubspotSubmit = lazy(() => import("../containers/HubspotSubmit"));
const OrderManagement = lazy(() => import("../containers/OrderManagement/OrderManagement"));
const NotificationMain = lazy(() => import("../containers/Notification/notificationMain"));

// const ProfileDetails = Loadable({
//   loader: () => import('../containers/ProfileDetails/profileDetails'),
//   loading: () => <div></div>,
// });

const Profile = lazy(() => import("../containers/ProfileDetails/Profile"));
const PaymentProcess = lazy(() => import("../containers/PaymentProcess"));
const IntermediaryPlatform = lazy(() => import("../containers/Home/IntermediaryPlatform"));
const InvestorPlatform = lazy(() => import("../containers/Home/InvestorPlatform"));
//Signup
// const SignUpMain = Loadable({
//   loader: () => import('../containers/Home/SignUpMain'),
//   loading: () => <div></div>,
// });
//PreLogin Investment
const OpenAccount = lazy(() => import("../containers/Home/OpenAccount"));
// const Support = lazy(() => import("../containers/Home/Support"));
const YourDreams = lazy(() => import("../containers/Home/YourDreams"));
const PreLoginInvestment = lazy(() => import("../containers/Home/PreLoginInvestment"));
const InvestmentProduct = lazy(() => import("../containers/InvestmentProduct/InvestmentProduct"));
const Blogs = lazy(() => import("../containers/Home/Blogs"));
const BlogsDetail = lazy(() => import("../containers/Home/BlogsDetail"));
const PreLoginFundDetails = lazy(() => import("../containers/Home/FundDetails"));
const Report = lazy(() => import("../containers/ClientDashboard/ReportUi"));
const RetireRichReportUi = lazy(() => import("../containers/ClientDashboard/RetireRichReportUi"));
const PreLoginAdvisorMain = lazy(() => import("../containers/PreLoginAdvisor/PreLoginAdvisorMain"));
const DeveloperLog = lazy(()=>import("../containers/DeveloperLog"))
const GoalsModified = lazy(() => import("../containers/ModifiedUi/GoalsModified"));

// const Home = Loadable({
//   loader: () => import("../containers/Home/Home"),
//   loading: () => <div></div>,
// });
//Investment component



const Routes = () => {
  // const [subdomain, setSubdomain] = useState("");
  // const [isSubdomainVerified, setSubdomainVerified] = useState(false);

  // async function verifyDSubdomain() {
  //   const host = window.location.host;
  //   const hostArr = host.includes("localhost")
  //     ? host.split(".").slice(0, -1)
  //     : host.split(".").slice(0, -2);
  //   // arr = host.split(".").slice(0, host.includes("localhost") ? -1 : -2);
  //   // arr = host.split(".").slice(0, host.includes("finapp") ? -2 : -3);

  //   if (hostArr.length > 0) {
  //     let values = {
  //       subdomain: hostArr[0],
  //     };
  //     try {
  //       const res = await verifySubdomain(values);
  //       setValue("isSubdomainVerified", res.isVerified);
  //       setSubdomain(hostArr[0]);
  //       setSubdomainVerified(res.isVerified);
  //     } catch (err) {
  //       console.error("verifyDSubdomain, Server err: ", err.response);
  //     }
  //   } else {
  //     setSubdomainVerified(true);
  //   }
  // }

  // useEffect(() => {
  //   verifyDSubdomain();
  // }, []);

  return (
    <Router>
      <Suspense
        fallback={
          <div class="text-center">
            <ContentLoader />
          </div>
        }
      >
        <Switch>
          <Route
            exact
            path="/"
            render={(props) => (
              <MainLayout {...props}>
                <Home1 {...props} />
              </MainLayout>
            )}
          />
          <Route
            exact
            path="/advisor"
            render={(props) => (
              <AdvisorMainLayout {...props}>
                <PreLoginAdvisorMain {...props} />
              </AdvisorMainLayout>
            )}
          />
          <Route
            exact
            path="/Login-Advisor"
            // render={(props) => (
            //   <MainLayout {...props}>
            //     <PreLoginAdvisorMain {...props} />
            //   </MainLayout>
            // )}

            render={(props) => (
              <AdvisorMainLayout {...props}>
             <AdvisorLogin {...props} />
              </AdvisorMainLayout>
             
            )}
          />
          <Route
            exact
            path="/advisor-blog"
            render={(props) => (
              <AdvisorMainLayout {...props}>
             <AdvisorBlogs {...props} />
              </AdvisorMainLayout>
             
            )}
          />
          <Route
            path="/advisor_blog_details"
            render={(props) => (
              <AdvisorMainLayout {...props}>
             <AdvisorBlogDetail  />
              </AdvisorMainLayout>
             
            )}
          />
           <Route
            path="/about-us"
            render={(props) => (
              <AdvisorMainLayout {...props}>
             <AboutUs  />
              </AdvisorMainLayout>
             
            )}
          />
           <Route
            path="/privacy-policy"
            render={(props) => (
              <AdvisorMainLayout {...props}>
             <Privacy  />
              </AdvisorMainLayout>
            )}
          />
           <Route
            path="/refund-policy"
            render={(props) => (
              <AdvisorMainLayout {...props}>
             <Refund  />
              </AdvisorMainLayout>
            )}
          />
           <Route
            path="/terms-conditions"
            render={(props) => (
              <AdvisorMainLayout {...props}>
             <TermsCondition  />
              </AdvisorMainLayout>
            )}
          />
          {/* <Route
            exact
            path="/SignUp"
            render={(props) => (
               <MainLayout {...props}>
              <AdvisorSignUpModal {...props} />
             </MainLayout>
             
            )}
          /> */}
           <Route
            exact
            path="/mandatory-step"
            render={(props) => (
              // <MainLayout {...props}>
                <MandatoryStep {...props} />
              // </MainLayout>
            )}
          />
           <Route
            exact
            path="/onboarding-step"
            render={(props) => (
              // <MainLayout {...props}>
                <OnBoardingStep {...props} />
              // </MainLayout>
            )}
          />
          <Route
            exact
            path="/subdomain_not_verified"
            render={(props) => (
              <SubdomainNotVerifiedMainLayout {...props}>
                <SubdomainNotVerifiedHome {...props} />
              </SubdomainNotVerifiedMainLayout>
            )}
          />
          {/* <Route
            exact
            path="/"
            render={(props) => (
              <Fragment>
                {isSubdomainVerified && (
                  <MainLayout {...props}>
                    <Home {...props} />
                  </MainLayout>
                )}
                {!isSubdomainVerified && (
                  <SubdomainNotVerifiedMainLayout {...props}>
                    <SubdomainNotVerifiedHome {...props} />
                  </SubdomainNotVerifiedMainLayout>
                )}
              </Fragment>
            )}
          /> */}
          {/*pre login nav*/}
          <Route
            exact
            path="/Tools-Resources"
            render={(props) => (
              <MainLayout {...props}>
                <ToolsResources {...props} />
              </MainLayout>
            )}
          />
          <Route
            exact
            path="/DeveloperLog"
            render={(props) => (
              <MainLayout {...props}>
                <DeveloperLog {...props} />
              </MainLayout>
            )}
          />
          <Route
            exact
            path="/login/"
            render={(props) => (
              <MainLayout {...props}>
                <Login {...props} />
              </MainLayout>
            )}
          />
          <Route 
            exact 
            path="/forgot_password" 
            render={props => (
            <MainLayout {...props}>
              <ForgotPassword {...props} />
              </MainLayout>
            )}
          />
            <Route 
            exact 
            path="/reset_password" 
            render={props => (
            <MainLayout {...props}>
              <ResetPassword {...props} />
              </MainLayout>
            )}
          />
          <Route
            exact
            path="/User-Journey"
            render={(props) => (
              <MainLayout {...props}>
                <UserJourney {...props} />
              </MainLayout>
            )}
          />
          <Route
            exact
            path="/fin-health"
            render={(props) => (
              <MainLayout {...props}>
                <Brief {...props} />
              </MainLayout>
            )}
          />
          <Route path="/404" component={Page404} />

          <Route
            exact
            path="/Intermediary-Platform"
            render={(props) => (
              <MainLayout {...props}>
                <IntermediaryPlatform {...props} />
              </MainLayout>
            )}
          />
          <Route
            exact
            path="/Investor-Platform"
            render={(props) => (
              <MainLayout {...props}>
                <InvestorPlatform {...props} />
              </MainLayout>
            )}
          />
          <Route
            exact
            path="/Open-Account"
            render={(props) => (
              <MainLayout {...props}>
                {/* <ClientSignUp {...props} /> */}
                <OpenAccount {...props} />
              </MainLayout>
            )}
          />
          <Route
            exact
            path="/Intermediary-Signup"
            render={(props) => (
              <MainLayout {...props}>
                <AdvisorSignUp {...props} />
              </MainLayout>
            )}
          />
          <Route
            exact
            path="/InvestmentProduct"
            render={(props) => (
              <MainLayout {...props}>
                <InvestmentProduct {...props} />
              </MainLayout>
            )}
          />
          <Route
            exact
            path="/PreLogin-Investment"
            render={(props) => (
              <MainLayout {...props}>
                <PreLoginInvestment {...props} />
              </MainLayout>
            )}
          />
          {/* <Route
            exact
            path="/Support"
            render={(props) => (
              <MainLayout {...props}>
                <Support {...props} />
              </MainLayout>
            )}
          /> */}
          <Route
            exact
            path="/Your-Dreams"
            render={(props) => (
              <MainLayout {...props}>
                <YourDreams {...props} />
              </MainLayout>
            )}
          />
          {/* <Route
            exact
            path="/Open-Account"
            render={(props) => (
              <MainLayout {...props}>
                <OpenAccount {...props} />
              </MainLayout>
            )}
          /> */}

          <Route
            exact
            path="/Blogs"
            render={(props) => (
              <MainLayout {...props}>
                <Blogs {...props} />
              </MainLayout>
            )}
          />
          <Route
            path="/Blogs_detailed"
            render={(props) => (
              <MainLayout {...props}>
                <BlogsDetail {...props} />
              </MainLayout>
            )}
          />
          <Route
            path="/mutual-funds"
            render={(props) => (
              <MainLayout {...props}>
                <PreLoginFundDetails {...props} />
              </MainLayout>
            )}
          />
          <Route
            path="/mutual_funds"
            render={(props) => (
              // <MainLayout {...props}>
                <PreLoginFundDetails {...props} />
              // </MainLayout>
            )}
          />
          <Route path="/download_report" component={Report} />
          <Route path="/download_retireRichReportUi" component={RetireRichReportUi} />
          <AppLayout>
            <Switch>
              {/* admin-dashboard */}
              <PrivateRoute path="/admin-dashboard" component={AdminDashboard} exact />
              {/* advisor-dashboard */}
              <PrivateRoute path="/advisor/dashboard" component={AdvisorDashboard} exact />
              {/*client mandatory step before using finapp*/}
              {/* <PrivateRoute path="/mandatory-step" component={MandatoryStep} exact /> */}
              {/* Advisor mandatory step */}
              <PrivateRoute path="/advisor/my-clients/mandatory-step" component={AdvisorMandatoryStep} exact />
              {/*clientDashboarRoutes*/}
              <PrivateRoute path="/client-dashboard" component={ClientDashboard} exact />
              {/* Goals routes */}
              <PrivateRoute path="/goals" component={GoalsModified} />

              <PrivateRoute exact path="/client-mapping" component={ClientMappingMain} />
              <PrivateRoute exact path="/news-letter" component={NewsLetter} />
              <PrivateRoute exact path="/advisor/aof" component={AccountCreation} />
              <PrivateRoute exact path="/advisor/rta/rtas" component={UploadRtas} />

              {/* Advisor Fund Analytics */}
              <PrivateRoute path="/advisor/fund-analytics" component={fundAnalyticsMain} />
              <PrivateRoute path="/advisor/my-clients" component={MyClients} />

              <PrivateRoute exact path="/advisor/notification" component={NotificationMain} />
              <PrivateRoute exact path="/advisor/help" component={HubspotForm} />
              {/* Advisor Portfolio Analytics */}
              <PrivateRoute
                path="/advisor/Portfolio-Analytics"
                component={PortfolioAnalyticsMain}
              />

              {/* Business Analytics */}
              <PrivateRoute path="/advisor/business-analytics" component={BusinessAnalyticsMain} />

              {/*onboarding new route*/}
              <PrivateRoute path="/advisor/onboarding" component={OnBoarding} />
              {/*admin rta*/}
              <PrivateRoute path="/rta" component={RtaAdmin} />
              {/* transaction routes */}
              <PrivateRoute path="/transaction" component={Transaction} />
              {/* holding routes */}
              <PrivateRoute path="/holding" component={HoldingMain} />
              {/* investment routes */}
              <PrivateRoute path="/investment" component={Investment} />
              {/* AssetMapping routes */}
              <PrivateRoute path="/AssetMapping" component={AssetMapping} />
              {/* Client setting */}
              <PrivateRoute path="/admin-setting" component={AdminSettingMain} />
              {/* Financial Health routes */}
              <PrivateRoute
                exact
                path="/financial-health"
                render={() => <Redirect to="/financial-health/Brief" />}
              />
              <PrivateRoute exact path="/financial-health/Brief" component={Brief} />
              {/* risk profiling routes */}
              <PrivateRoute
                exact
                path="/risk-profiling"
                render={() => <Redirect to="/risk-profiling/Details" />}
              />
              <PrivateRoute exact path="/risk-profiling/Details" component={Details} />
              <PrivateRoute exact path="/dreams" component={DreamsNav} />
              <PrivateRoute path="/dreams/main" component={DreamsMain} />
              {/* assets routes */}
              <PrivateRoute path="/assets" component={AssetsMain} />

              <PrivateRoute path="/client-dashboard/main" component={DreamsMain} />
              <PrivateRoute path="/client-dashboard/ideas-main" component={IdeasMain} />
              <PrivateRoute path="/client-dashboard/payment" component={PaymentPage} />

              <PrivateRoute exact path="/cart" component={Cart} />
              <PrivateRoute exact path="/order-management" component={OrderManagement} />
              <PrivateRoute exact path="/notification" component={NotificationMain} />
              <PrivateRoute exact path="/help" component={HubspotForm} />
              <PrivateRoute exact path="/query_Submit" component={HubspotSubmit} />

              {/*Profile Details */}
              <PrivateRoute exact path="/profile-details" component={Profile} />
              <PrivateRoute exact path="/payment-process" component={PaymentProcess} />

              {/* <Route path="*" component={NotFound} /> */}
              <Route path="*" render={() => <Redirect to="/404" />} />
            </Switch>
          </AppLayout>
        </Switch>
      </Suspense>
    </Router>
  );
};

export default Routes;
